<template>
  <div class="">
    <div v-show="isShow">
      <div ref="observerTarget" class="max-width flex flex-col gap-y-[8px]">
        <HomeSectionTitle :title="punchLine" />
        <div class="h-[228px]">
          <AdaptiveSlider
            :swiper-option="swiperOption"
            class="top-categories-slider relative"
            wrapper-class=""
          >
            <template #default="{ slide }">
              <component
                :is="slide"
                v-for="(topCategory, index) in topCuisine"
                :key="topCategory.id + index"
                class="top-categories-slide"
              >
                <div class="md:pb-0">
                  <a
                    :href="buildLink(topCategory.id)"
                    class="top-categories-item block rounded-xl shadow-md"
                    @click.prevent="goToLink(topCategory, index + 1)"
                  >
                    <div class="cover relative">
                      <div class="relative h-[228px] w-[160px]">
                        <HhImage
                          v-if="!isLoading"
                          :width="150"
                          :height="228"
                          :src="
                            rebuildAssetURL(topCategory.attributes.cover.url)
                          "
                          :alt="topCategory.attributes.title"
                          class="absolute left-0 top-0 h-full w-full rounded-xl object-cover object-center"
                          :use-mutator="true"
                        />
                      </div>
                      <div
                        class="overlay absolute top-0 h-full w-full rounded-xl"
                      >
                        <div
                          class="absolute bottom-0 left-2 p-2 text-base lg:text-lg"
                        >
                          <div
                            class="truncate font-bold leading-5 text-white"
                            :class="
                              isLoading
                                ? ' mb-2 h-4  w-8 bg-gray-300 text-gray-300'
                                : null
                            "
                          >
                            {{ topCategory.attributes.title }}
                          </div>
                          <div class="flex items-center text-base">
                            <span class="text-[12px] text-white lg:text-sm">
                              {{
                                `${
                                  topCategory.attributes.totalRestaurants
                                } ${$t(
                                  "outlet",
                                  Number(
                                    topCategory.attributes.totalRestaurants
                                  )
                                )}`
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </component>
            </template>
          </AdaptiveSlider>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useIntersectionObserver } from "@vueuse/core";
import { onMounted, ref, type Ref, toRefs, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { getHomeSection } from "~/api/home_section/getHomeSection";
import alert from "~/lib/alert";
import useCityStore from "~/stores/city";
import AdaptiveSlider from "~/section/AdaptiveSlider.vue";
import HomeSectionTitle from "~/components/home/HomeSectionTitle.vue";
import useConfigStore from "~/stores/config";
import type { RestaurantTags } from "~/types/Restaurant";
import { rebuildAssetURL } from "~/helpers/url";
import type { HomepageModelEvent, SectionTemplate } from "~/types/Home";
import { useWindow } from "#imports";

const props = defineProps({
  apiOrder: {
    type: Number,
    default: 0,
  },
  endPointApi: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  punchLine: {
    type: String,
    default: "",
  },
  sectionTemplate: {
    type: String,
    default: "" as SectionTemplate,
  },
});

const emits = defineEmits<{
  (
    e: "on-tag-clicked",
    data: { tag: RestaurantTags; tagObjectEvent: HomepageModelEvent }
  ): void;
}>();
const configStore = useConfigStore();
const cityStore = useCityStore();
const isShow = ref(true);
const observerTarget = ref(null);
const isVisible = ref(false);
const isLoading = ref(true);
const isDataLoaded = ref(false);
const { endPointApi, apiOrder } = toRefs(props);
const { selectedCityId } = storeToRefs(cityStore);
const { isHybrid } = storeToRefs(configStore);
const topCuisine: Ref<RestaurantTags[]> = ref([]);
const { stop } = useIntersectionObserver(
  observerTarget,
  ([{ isIntersecting }]) => {
    isVisible.value = isIntersecting;
    if (isVisible.value) {
      stop();
    }
  }
);
const swiperOption = computed(() => {
  return {
    slidesPerView: "auto",
    spaceBetween: 10,
    slidePerGroup: "auto",
    centerInsufficientSlides: true,
  };
});

const buildLink = (id: string) => {
  return `restaurants/search?city_id=${selectedCityId.value}&cuisines[]=${id}`;
};

const goToLink = (topCategory: RestaurantTags, index: number) => {
  const tagObjectEvent = {
    sectionName: props.title,
    sectionTemplate: props.sectionTemplate,
    tagId: parseInt(topCategory.id),
    tagName: topCategory.attributes.title,
    tagNameEN: topCategory.attributes.titleEn,
    tagType: topCategory.attributes.tagType,
    position: index.toString() || 0,
  } as HomepageModelEvent;
  if (isHybrid.value) {
    emits("on-tag-clicked", { tag: topCategory, tagObjectEvent });
    return;
  }

  const link = buildLink(topCategory.id);
  if (useWindow) {
    useWindow.location.href = link;
  }
};

async function fetchData(endPointApi: string) {
  isLoading.value = true;
  const { data, isSuccess, message } = await getHomeSection({
    order: apiOrder.value,
    endPointApi,
  });
  if (!isSuccess || !data) {
    alert.error(message);
    return;
  }
  if (data) {
    topCuisine.value = data.data as RestaurantTags[];
    isShow.value = topCuisine.value.length > 0;
    isLoading.value = false;
    isDataLoaded.value = true;
  }
}
onMounted(() => {
  watch(isVisible, (newVal) => {
    if (newVal === true && isDataLoaded.value === false) {
      fetchData(endPointApi.value);
    }
  });
  watch(selectedCityId, () => {
    fetchData(endPointApi.value);
  });
});

defineOptions({
  name: "TopCuisine",
});
</script>

<style lang="scss" scoped>
.top-categories-slider {
  .top-categories-slide {
    @apply mr-2 w-[160px];
  }

  .top-categories-slide:first-child {
    @apply ml-4;
  }

  .overlay {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 65.73%,
      rgba(0, 0, 0, 0.8) 99.66%
    );
  }
}

.top-categories-item {
  width: 160px;

  @screen md {
    width: 100px;
  }

  @screen lg {
    width: 125px;
  }

  @screen xl {
    width: 145px;
  }

  .cover {
    height: 228px;

    @screen md {
      height: 125px;
    }

    @screen lg {
      height: 150px;
    }

    @screen xl {
      height: 180px;
    }

    @apply rounded-xl;
  }
}
</style>
